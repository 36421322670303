function disableScrolling(){
    var x=window.scrollX;
    var y=window.scrollY;
    window.onscroll=function(){window.scrollTo(x, y);};
    document.querySelector('body').classList.add('nonscroll');
}

function enableScrolling(){
    window.onscroll=function(){};
    document.querySelector('body').classList.remove('nonscroll');

}
