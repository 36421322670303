function intervalImage($this){
    $this.find('img').addClass('inactive');
    var _roleImg = Number($this.attr('role-img'));
    var _countImg = Number($this.find('img').length) + 1;

    var _addRole = _roleImg + 1;
    var _returnrole = $this.attr('role-img', _addRole);
    if (_addRole >= _countImg){
      $this.attr('role-img', 1);
      $this.find("[data-role='1']").removeClass('inactive');
      clearInterval();
    }
    $this.find("img[data-role='" + _addRole + "']").removeClass('inactive');
}

var _timer = null;

$(document).on({
  mouseenter: function () {
    var $this = $(this);
    intervalImage($this);
    _timer = setInterval(function(){
      intervalImage($this);
    }, 1500);
  },
  mouseleave: function () {
    clearInterval(_timer);
    $(this).find('img').addClass('inactive');
    $(this).attr('role-img', 1);
    $(this).find("[data-role='1']").removeClass('inactive');

  }
}, '.product-area .product-image a');


intervalImage($('.banner-main picture'));
_timer = setInterval(function(){
  intervalImage($('.banner-main picture'))
}, 8000);
