var slideIndex = 1;
var imgTarget = document.getElementsByClassName("slideshow");
var counter = 0;


function plusDivs(n) {
  showDivs(slideIndex += n);
  counter = 0;
}

function showDivs(n) {
  var i;
  if(imgTarget.length > 0){
    if (n > imgTarget.length) {slideIndex = 1}
    if (n < 1) {slideIndex = imgTarget.length}
    for (i = 0; i < imgTarget.length; i++) {
       imgTarget[i].style.opacity    = '0' ;
       imgTarget[i].style.transition = '1s ease-in-out';
    }
    imgTarget[slideIndex - 1].style.opacity    = '1' ;
    imgTarget[slideIndex - 1].style.transition = '1s ease-in-out';
  }
}
showDivs(slideIndex);
 
setInterval(function() 
{
  counter++;  

  if(counter >= 5)
  {
    plusDivs(1);
  }  
}, 1000);