(function ($) {
  var body = $('body');
  $.fn.PopupVideo = function (options) {

    var s = $.extend({
      conteiner: "box-cg",
      id: "videoShowTime",
      title: "",
      poster: "",
      url: "",
      type: "video/mp4"
    }, options);


    var addVideo = '<div id="' + s.conteiner + '" class="playerpopup boxVideo contentVideo">\
                              <div class="head-menu"> <div class="f f-r"> <div class="col col-half"> \
                              <div class="head-title-video"> \
                              <span><i class="icon i-film">\
                              </i>&nbsp;&nbsp;'+ s.title + '</span> </div> </div> \
                              <div class="col col-half f f-rht"> \
                              <a id="closeboxvideo" href="#!" class="btncls-video">\
                              <i class="icon i-close"></i></a> </div> </div> </div>\
                              <video id="'+ s.id + '" poster="' + s.poster + '" loop controls>\
                              <source src="'+ s.url + '" type="' + s.type + '"> </video> </div>';

    body.append(addVideo);
    body.addClass("nonscroll");


    var videoHome = $("#" + s.id + "");
    var vid = videoHome[0];
    vid.play();
    disableScrolling();
    $("#closeboxvideo").click(function () {
      $("#" + s.conteiner + "").detach();
      $("body").removeClass("nonescroll");
      $("body").removeClass("nonscroll");
      enableScrolling();
    });

  }


}(jQuery));

var body = $('body');
body.on('click', 'header .submenu .thumbnail a', popupVideo)

function popupVideo() {
  var self = $(this)
  var getSrcVideo = self.data('video')
  var getTitle = self.attr('title')
  var getSrc = self.attr('src')
  body.PopupVideo({
    conteiner: 'box-cg',
    id: 'ChristopherGuy',
    title: getTitle,
    poster: getSrc,
    url: getSrcVideo,
    type: 'video/mp4'
  })
}
