function urlify(text) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function(url) {
        return '<a href="' + url + '" target="_blank">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

var removeHTML = function(text){
  var out = text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  return out;
}
var comment = {
  sendComment: function(){
    var input = $('.comment .action input').val();
    if(input){
      input = urlify(removeHTML(input));
      var target = $('.comment ul#comment-container');
      var html = "<li class='list-comment'>" +
      "<div class='f f-r f-end'>" +
      "<span class='name'>Mark Smith</span>" +
      "<span class='time'>Jul 16, 2018 05:50:03</span>" +
      "</div>" +
      "<p>"+input+"</p>" +
      "</li>";
      target.append(html);
      $('.comment .action input').val('');
      var targetJS = document.getElementById('comment-container');
      var scroller = targetJS.scrollHeight - targetJS.clientHeight;
      target.scrollTop(scroller)
    }
  },
  closeComment: function(){
    $('.comment').removeClass('on');
    enableScrolling()
  },
  openComment: function(){
    $('.comment').addClass('on');
    disableScrolling()
  }
}
// var sendComment = function(){
//   var input = $('.comment .action input').val();
//   if(input){
//     input = urlify(removeHTML(input));
//     var target = $('.comment ul#comment-container');
//     var html = "<li class='list-comment'>" +
//     "<div class='f f-r f-end'>" +
//     "<span class='name'>Mark Smith</span>" +
//     "<span class='time'>Jul 16, 2018 05:50:03</span>" +
//     "</div>" +
//     "<p>"+input+"</p>" +
//     "</li>";
//     target.append(html);
//     $('.comment .action input').val('');
//     var targetJS = document.getElementById('comment-container');
//     var scroller = targetJS.scrollHeight - targetJS.clientHeight;
//     target.scrollTop(scroller)
//   }
// }
//
// var closeComment = function(){
//   $('.comment').removeClass('on');
// }

$('body').on('click', '.comment .action button', function(){
  comment.sendComment();
});


$('.comment .action input').keyup(function (e) {
  if (e.which == 13) {
    comment.sendComment();

  } else if (e.which == 27) {
    $('.comment .action input').val('');
  }
});
var accordionMenu = function(el, target){
  var item = el;
  var targetEl = item.parent().find(target);

  if(targetEl.hasClass('on')){
    targetEl.removeClass('on');
    $('.product-detail .floating-action').removeClass('relative')
    item.find('i.icon').css('transform', 'rotateZ(0deg)')
  } else {
    $(target).removeClass('on');
    $('.product-detail .content ul a i.icon').css('transform', 'rotateZ(0deg)')
    item.find('i.icon').css('transform', 'rotateZ(45deg)')
    targetEl.addClass('on');
    switchFloating();
  }
}

var switchFloating = function(){
  var h = $(window).height();
  var floating = $('.product-detail .floating-action');
  var floatingHeight = floating.height();
  var cont = $('.product-detail .content');
  var informationHeight = cont.height() + floatingHeight + 105 + 13 + 59 + 28;
  // var informationHeight = $('.product-detail .information').height() + 105 + floatingHeight;
  if(informationHeight > h){
    floating.addClass('relative');
  }
  else {
    floating.removeClass('relative');
  }
}
$(document).ready(function(){
  $('body').on('click', '.product-detail .content ul a.hasContent', function(){
    accordionMenu($(this), ".content-detail");
  });

  $('body').on('click', '.product-detail .content ul a.hasFloat', function(){
    const wrapper = $(this).parent().find('.float-wrapper')
    wrapper.addClass('show')
  })
  $('body').on('click', '.product-detail .float-wrapper .float-header i', function(){
    const wrapper = $(this).closest('.float-wrapper')
    wrapper.removeClass('show')
  })
});
