var sidebar = {
  close: function(){
    $('.folder .sidebar').removeClass('on');
    $('.folder .content').removeClass('overlay');
    enableScrolling();
  },
  open: function(){
    $('.folder .sidebar').addClass('on');
    $('.folder .content').addClass('overlay');
    disableScrolling();
  },
  toggle: function(el){
    var x = el.parentElement.classList.contains('on');
    if(x){
      sidebar.close();
    } else {
      sidebar.open();
    }
  }
}


var detailFabric = {
  open: function(el){
    el.parents('li').find('.product-detail').addClass('on');
  },
  close: function(el){
    el.parents('li').find('.product-detail').removeClass('on');
  },
  toggle: function(el){
    if(el.parents('li').find('.product-detail').hasClass('on')){
      detailFabric.close(el);
    } else {
      detailFabric.open(el);
    }
  }
}

$('body').on('click', '.folder .information .g-0 a', function(){
  // $(this).parents('li').find('.product-detail').addClass('on');
  detailFabric.toggle($(this));
});
$('body').on('mouseleave', '.folder .information .g-0 a', function(){
  $(this).parents('li').find('.product-detail').removeClass('on');
});
