var notification = {
  show: function(type, text, duration){
    var prop = {
      type : type,
      text : text,
      duration : duration
    }
    var html = "<div class='notification "+prop.type+"' style='transform: translateY(-100%)'>"+
              "<p class='f f-ctr mdl'>"+prop.text+"</p>"+
              "<a href='javascript:void(0)' onclick='notification.close()'>"+
              "<i class='icon i-close-rounded'></i>"+
              "</a>"+
              "</div>";
    $('body').append(html);
    setTimeout(function(){
      $('.notification').css('transform', 'translateY(0)');
    },10)

    setTimeout(function(){
      $('.notification').css('transform', 'translateY(-100%)');
    }, prop.duration)

    setTimeout(function(){
      $('.notification').detach();
    }, prop.duration + 325)
  },
  close: function(){
    $('.notification').css('transform', 'translateY(-100%)');
    setTimeout(function(){
      $('.notification').detach();
    }, 325)
  }
}

var loader = {
  show: function(){
    var html = "<div class='loader-global f f-ctr mdl f-c' style='display: none;'>"+
    "<div class='loader loader--style2'>"+
    "<svg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='40px' height='40px' viewBox='0 0 50 50' style='enable-background:new 0 0 50 50;' xml:space='preserve'>"+
    "<path fill='#000' d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z' transform='rotate(179.786 25 25)'>"+
    "<animateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'></animateTransform>"+
    "</path>"+
    "</svg>"+
    "</div>"+
    "<p>Please Wait</p>"+
    "</div>";

    $('body').append(html);
    $('body > .loader-global').css('display', 'flex');
    setTimeout(function(){
      $('body > .loader-global').addClass('on');
    },10)
  },
  hide: function(){
    $('body > .loader-global').removeClass('on');
    setTimeout(function(){
      $('body > .loader-global').detach();
    }, 125)
  }
}
