var barcode = {
  target: $('#qrcode'),
  open: function(){
    $('#qrcode').css('display', 'block');
    disableScrolling(); 
    setTimeout(function(){
      $('#qrcode').addClass('on');

    }, 50)

    // $('<script type="module" id="qrCodeScript">\
    //  import  QrScanner from "../qr-scanner.min.js";\
    //  callsrc()\
    //  </' + 'script>').appendTo(document.body)
    },
  close: function(){
    this.target.removeClass('on');
    enableScrolling();
    setTimeout(function(){
      $('#qrcode').css('display', 'none');
    }, 200)
  }
}
