var popup = {
  callById: function (id) {
    $('.popup#' + id + '').addClass('on');
    disableScrolling();
  },
  closeById: function (id) {
    $('.popup#' + id + '').removeClass('on');
  },
  closeThisPopup: function (el) {
    var item = el;
    if (item) {
      item.closest('.popup').classList.remove('on');
    }
    enableScrolling();
  },
  closePopup: function () {
    $('.popup').removeClass('on');
    enableScrolling();
  },
  callPopup: function (el) {
    var item = el;
    var dataPopup = item.getAttribute('data-popup');

    $('.popup[id=\'' + dataPopup + '\']').addClass('on');

    disableScrolling();

  },
  collectInformation: function () {
    $('.popup[id=\'collectInformation\']').addClass('on');
    disableScrolling();
  },
  getToken: function () {
    $('.popup[id=\'getTokenInfo\']').addClass('on');
    disableScrolling();
  }
};

const video = {
  stopVideo: e => {
    // const player = e.closest('.popup').querySelector('#filmplayer-video')
    const player = videojs('filmplayer-video');
    if (player) {
      player.pause()
      player.currentTime(0)
      // player.pause()
      // player.src('');
      // player.currentTime = 0
    }
  },
  setVideo: () => {
    const cards = document.querySelectorAll('.media-card[video-url]')
    for (let card of cards) {
      card.addEventListener('click', function () {
        const urls = JSON.parse(card.getAttribute('video-url'))
        const screenWidth = window.innerWidth;
        const src = urls.map((i, index) => {
          const con = navigator.connection || navigator.mozConnection || navigator.webkitConnetion
          const connection = con ? con.downlink : 10;
          let selected;
          if(screenWidth > 992){
            if(connection > 5){
              selected = index === 0
            } else {
              selected = index === urls.length - 1
            }
          } else {
            selected = index === urls.length - 1
          }
          return {
            type: 'video/mp4',
            src: i.url,
            label: i.label,
            selected
          }
        })
        console.log(src)
        const player = videojs('filmplayer-video', {
          controlBar: {
            children: [
              'playToggle',
              'progressControl',
              'volumePanel',
              'qualitySelector',
              'fullscreenToggle',
           ],
          }
        });
        player.src(src);
        player.ready(function () {
          player.play();
        });
      })
    }
  }
}

video.setVideo()

var resetGetTokenInfo = function () {
  $('#getTokenInfo select.styled').val("by-email");
  $("#getTokenInfo input[type='text']").val('');
  $('#getTokenInfo .token-method > div').css('display', 'none');
  $('#getTokenInfo .token-method #by-email').css('display', 'block');
  $('#getTokenInfo #by-qr .loading-qr').css('display', 'none');
}

$(document).ready(function () {
  $('body').on('click', '.popup .overlay', function () {
    popup.closePopup();
  });
  $('body').keyup(function (e) {
    if (e.which == 27) {
      popup.closePopup();
    }
  });
  $('body').on('click', '.select-folder li > a', function () {
    // popup.closePopup();
    popup.callById("detailInformation");

  });
});
