
var searchBox = {
  open: function () {
    $('header .search-box').addClass('on');
    $('header .tools li > a').css('display', 'none');
  },
  close: function () {
    $('header .search-box').removeClass('on');
    $('header .tools li > a').css('display', 'flex');
    setTimeout(function () {
      $('header .search-box input').val('');
    }, 200)
  }
}

$('header .search-box input').keyup(function (e) {
  if (e.which == 27) {
    searchBox.close()
  }
});

$(document).ready(function () {

  setTimeout(function () {
    $('header').css({
      'transform': 'translateY(0)',
      'opacity': 1
    });
    $('.fullshow .full-content').css('opacity', 1);
  }, 500);
  var showHeaderThumbnail = function (e) {
    var dataThumb = e.attr('data-thumb');
    var target = e.parents('.inner-menu').find('li.image-preview img');
    target.attr('src', dataThumb);
  }

  $('body').on('mouseover', '.inner-menu li a[data-thumb]', function () {
    showHeaderThumbnail($(this));
  });

  $('body').on('mouseleave', '.inner-menu li a[data-thumb]', function () {
    $(this).parents('.inner-menu').find('li.image-preview img').attr('src', '');
  });



  var smallMenu = function (e) {
    var item = e;
    // var itemTop = item.offset().top;
    var itemTop = item.get(0).getBoundingClientRect().top;

    const headerHeight = $('header').height();
    var childTarget = item.find('.inner-menu');
    var calc = (itemTop - headerHeight) * -1;
    // console.log(itemTop, headerHeight, calc, item.get(0).getBoundingClientRect());
    if (!item.hasClass('on')) {
      $('header .main-menu .menu>li').addClass('hidden');
      $('header .main-menu .menu>li').removeAttr('style');
      item.removeClass('hidden');
      item.addClass('on');
      setTimeout(function () {
        item.css('transform', "translateY(" + calc + "px)");
        childTarget.addClass('active');
      }, 200);
    } else {
      childTarget.removeClass('active');
      item.css('transform', 'translateY(0)');
      setTimeout(function () {
        $('header .main-menu .menu>li').removeClass('hidden on');
        $('header .main-menu .menu>li').removeAttr('style');
      }, 200);
    }

  }

  $('body').on('click', 'header .main-menu.sub-menu .menu>li:not(.no-inner)', function (e) {
    if (e.target !== this) {
      return
    } else {
      if ($(document).width() <= 992) {
        smallMenu($(this))
      }
    }
  });

  $('body').on('click', 'header .main-menu:not(.sub-menu) .menu > li', function (e) {
    if (e.target !== this) {
      return
    } else {
      if ($(document).width() <= 992) {
        const item = $(this)
        const allItem = $('header .main-menu:not(.sub-menu) .menu > li')
        const itemTop = item.get(0).getBoundingClientRect().top;
        const headerHeight = $('header').height();
        const calc = (itemTop - headerHeight) * -1;

        const currentData = $(this).find('a').attr('data-menu')
        const subMenuTarget = $(`header .main-menu.sub-menu ul#${currentData}`)
        const subMenu = $('header .main-menu.sub-menu ul')
        const subMenuWrapper = $('header .main-menu.sub-menu')

        if (subMenuWrapper.hasClass('active')) {
          subMenuWrapper.removeClass('active')
          subMenu.removeClass('active')
          allItem.removeClass('hidden')
          item.removeAttr('style')

        } else {
          subMenuWrapper.addClass('active')
          subMenuTarget.addClass('active')
          allItem.addClass('hidden')
          item.removeClass('hidden')
          item.css('transform', "translateY(" + calc + "px)");
        }
      }
    }
  })

  $('body').on('click', 'header a#toggle-smallmenu', function () {

    $('header .main-menu .menu').toggleClass('on');
    $('body').toggleClass('nonscroll')
  });
});


const headerHover = () => {
  const mainMenu = document.querySelectorAll('.main-menu:not(.sub-menu) ul.menu > li > a');
  const currentActive = document.querySelectorAll('.main-menu:not(.sub-menu) ul.menu > li > a.active');
  const currentData = currentActive[0].getAttribute('data-menu')
  const header = document.querySelector('header')
  Object.values(mainMenu).map(element => {
    const dataMenu = element.getAttribute('data-menu')
    const subMenu = document.querySelector(`.sub-menu ul#${dataMenu}`);
    const allSubMenu = document.querySelectorAll('.sub-menu > ul.menu')
    element.addEventListener('mouseover', function () {
      Object.values(allSubMenu).map(i => i.classList.remove('active'))
      Object.values(mainMenu).map(i => i.classList.remove('active'))
      element.classList.add('active')
      subMenu.classList.add('active')
    })
    header.addEventListener('mouseleave', function () {
      Object.values(allSubMenu).map(i => i.classList.remove('active'))
      Object.values(mainMenu).map(i => i.classList.remove('active'))
      const currentSubMenu = document.querySelector(`.sub-menu ul#${currentData}`);
      const currentElement = document.querySelector(`.main-menu:not(.sub-menu) ul.menu > li > a[data-menu='${currentData}']`)
      currentSubMenu.classList.add('active')
      currentElement.classList.add('active')
    })
  })
}

headerHover()